
import { defineComponent } from 'vue'

// Utils
import utilities from "../utilities";

export default defineComponent({
    name: "MyAppointments",
    data(){
        return{
            userId: '',
            loading: false,
            status: {
                pending: {
                    'translation': 'Pendiente',
                    'color': '#ff9800'
                },
                paid: {
                    'translation': 'Pago',
                    'color': '#4caf50'
                },
                // printing: {
                //     translation: 'Imprimiendo',
                //     color: '#ff9800'
                // },
                // 'on-way': {
                //     translation: 'En Camino',
                //     color: '#673ab7'
                // },
                // unknown: {
                //     translation: 'Desconocido',
                //     color: '#f44336'
                // },
                // delivered: {
                //     translation: 'Entregado',
                //     color: '#2196f3'
                // }
            },
            appointments: []
        }
    },

    mounted(){
        this.loading = true
        //check auth and get token
        let checkAuth = utilities.checkAuth('mi-cuenta') as any
        Promise.resolve(checkAuth).then((data) => {
            this.userId = data.data.id as any

            //get appointments
            //let appointments= utilities.getMyAppointments(this.userId) as any
            // Promise.resolve(appointments).then((data: any[any]) => {
            //     this.appointments = data
                
                /*this.appointments.forEach( (appointment:any[any], index:number) =>{
                    console.log(index)
                    if(appointment.status == 'pending'){
                        (this.appointments[index] as string) = 'pendiente'
                    }
                })*/

            //     this.loading = false
            // })
        })
    },

    computed: {
        appointmentlist: function (){
            return this.appointments;
        }
    }
})
